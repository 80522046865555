.app {
  display: flex;
}

.sidebar {
  width: 300px;
  background-color: #10161d;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.editor-section {
  padding: 16px;
  padding-left: 0;
  border-bottom: 1px solid white;
}
.editor-section:first-of-type {
  padding-top: 0;
}
.editor-section > p {
  margin: 0;
  padding: 8px;
}
.editor-section > p:hover {
  cursor: pointer;
  background-color: #1c2632;
}
.editor-header {
  text-transform: uppercase;
  margin: 8px 0;
}
.flex-spacer {
  flex-grow: 1;
}
.logo {
  margin: 0 auto;
}
nav {
  display: flex;
}
nav > a {
  padding: 16px;
  font-size: 1.2rem;
  color: #10161d;
  text-decoration: none;
}
nav > a:hover {
  border: none;
}
main {
  display: flex;
  flex-direction: column;
  align-content: center;
  box-sizing: border-box;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  text-align: center;
}
.meme {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
.quote-block {
  position: absolute;
  font-size: 1.4rem;
  font-weight: bold;
  width: 836px;
  color: black;
  padding: 16px;
  margin: 0 16px;
}
.quote-block > h3 {
  font-size: 2rem;
}
.quote-block > h3:first-of-type {
  margin-top: 0;
}
.quote-block > h3:last-of-type {
  margin-bottom: 0;
}
.meme-image {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
p {
  font-size: 1rem;
}
button {
  background: transparent;
  font-size: 1rem;
  padding: 12px 12px;
  color: white;
  margin: 32px 0;
  border-radius: 6px;
}
button:hover {
  background-color: white;
  color: #10161d;
  cursor: pointer;
}
button:last-of-type {
  margin-top: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

